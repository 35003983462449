import { FaTag } from 'react-icons/fa/'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import StoreContext, { defaultStoreContext } from '../context/StoreContext'
// import { ThemeContext } from "../layouts";
import Article from '../components/Article/'
import Headline from '../components/Article/Headline'
import List from '../components/List'
import SEO from '../components/SEO'
import PageHeader from '../components/Shared/PageHeader'

const CategoryPage = props => {
  const {
    data: {
      posts: { edges: posts },
      site: {
        siteMetadata: { facebook },
      },
    },
  } = props

  const breadcrumb = [
    { title: 'Home', url: '/' },
    { title: 'Categories', url: '/category' },
  ]

  // Create category list
  const categories = {}
  posts.forEach(edge => {
    const {
      node: {
        frontmatter: { category },
      },
    } = edge

    if (category && category != null) {
      if (!categories[category]) {
        categories[category] = []
      }
      categories[category].push(edge)
    }
  })

  const categoryList = []

  for (var key in categories) {
    categoryList.push([key, categories[key]])
  }

  return (
    <React.Fragment>
      <PageHeader header="Categories" breadcrumb={breadcrumb} />
      <StoreContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="Posts by categories" theme={theme} />
            </header>
            {categoryList.map(item => (
              <section key={item[0]}>
                <h2>
                  <FaTag /> {item[0]}
                </h2>
                <List edges={item[1]} theme={theme} />
              </section>
            ))}
          </Article>
        )}
      </StoreContext.Consumer>

      <SEO facebook={facebook} />
    </React.Fragment>
  )
}

CategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CategoryPage

//eslint-disable-next-line no-undef
export const query = graphql`
  query PostsQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`
